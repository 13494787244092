body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #111827;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

html {
  height: 100%;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="password"] {
  font-size: 16px;
}

:root {
  touch-action: auto;
  height: 100%
}

.slick-prev:before, .slick-next:before{
  color: #88CD96;
  font-size: 30px;
}

.slick-prev {
  /*left: 50px !important;*/
  left: 0 !important;
}
.slick-next {
  /*right: 50px !important;*/
  right: 10px !important;
}

.slick-list {
  margin-right: 25px;
  margin-left: 25px;
}

.slick-dots {
  bottom: -15px;
}

.slick-slide {
  padding: 0 8px;
  box-sizing: border-box;
}

.MuiTypography-root {
  font-family: Poppins, sans-serif !important;
}

.MuiTypography-h3 {
  font-weight: 700 !important;

  @media only screen and (max-width: 768px) {
    font-size: 24px !important;
  }
}

.MuiTypography-h6 {
  font-weight: 700 !important;

  @media only screen and (max-width: 768px) {
    font-size: 16px !important;
  }
}

.MuiTypography-h5 {
  @media only screen and (max-width: 768px) {
    font-size: 16px !important;
  }
}

.MuiButton-contained {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: Poppins, sans-serif !important;
  background-color: #FF5757 !important;
  border-radius: 10px !important;
  text-transform: none !important;
}
.MuiButton-contained.Mui-disabled {
  opacity: 0.6 !important; /* Optional: Adjust opacity */
}

.MuiButton-outlined {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: Poppins, sans-serif !important;
  border-radius: 10px !important;
  text-transform: none !important;
  border-color: rgba(255, 255, 255, 0.19) !important;
  color: white !important;
}

.MuiButton-outlined:disabled {
  color: rgba(255, 255, 255, 0.19) !important;
}

.MuiDialog-container .MuiPaper-root {
  background-color: #1B2436 !important;
  border-radius: 20px;
}

.MuiOutlinedInput-root {
  background-color: #111827 !important;
  box-shadow: inset 0 0 18px 0 rgba(0, 0, 0, 0.15) !important;
  border: 1px solid rgba(255, 255, 255, 0.16) !important;
  color: white;
  border-radius: 5px !important;
}

.MuiOutlinedInput-root svg {
  fill: white !important;
}

.MuiInputLabel-root {
  color: white !important;
}

.MuiOutlinedInput-input {
color: white !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.16) !important;
}

.MuiInputLabel-shrink {
  color: white !important;
}

.MuiInputAdornment-root .MuiButtonBase-root {
  background-color: #FF5757 !important;
  border-radius: 8px !important;
}

.MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root {
  fill: white;
}

.MuiPaper-root .MuiAlert-standardWarning, .MuiAlert-standardWarning {
  color: #FFC657 !important;
  align-items: center !important;
  border: 2px dotted #FFC657 !important;
  background-color: rgba(255, 198, 87, 0.06) !important;
}

.MuiAlert-standardWarning path {
  fill: #FFC657 !important;
}

.MuiPaper-root .MuiAlert-standardInfo  {
  color: #FFC657 !important;
  align-items: center !important;
  border: 2px dotted #FFC657 !important;
  background-color: rgba(255, 198, 87, 0.06) !important;
}

.MuiAlert-standardInfo path {
  fill: #FFC657 !important;
}

.MuiPaper-root .MuiAlert-standardError, .MuiAlert-standardError  {
  color: #ff2a4b !important;
  align-items: center !important;
  border: 2px dotted #ff2a4b !important;
  background-color: rgba(255, 198, 87, 0.06) !important;
}

.MuiAlert-standardError path {
  fill: #ff2a4b !important;
}

.MuiPaper-root .MuiAlert-standardSuccess {
  color: #88CD96 !important;
  align-items: center !important;
  border: 2px dotted #88CD96 !important;
  background-color: rgba(136, 205, 150, 0.06) !important;
}

.MuiAlert-standardSuccess path {
  fill: #88CD96 !important;
}

.MuiDrawer-paper {
  background-color: #111827 !important;
  color: white !important;
}

.MuiPopover-paper {
  background-color: #111827 !important;
  color: white !important;
}

.cs-message-list {
  background-color: #e9e4de !important;
}

.scrollbar-container.cs-message-list__scroll-wrapper.ps.ps--active-y.ps--scrolling-y {
  overflow-y: auto !important;
}

.scrollbar-container.cs-message-list__scroll-wrapper.ps.ps--active-y.ps--scrolling-y::-webkit-scrollbar {
  display: none;
}

.scrollbar-container.cs-message-list__scroll-wrapper.ps.ps--active-y.ps--scrolling-y::-webkit-scrollbar-track {
  display: none;
}

.ps__rail-y {
  display: none !important;
}

.cs-message__avatar img, .cs-message-group__avatar img {
  object-fit: contain !important;
  background-color: #868686 !important;
}

.cs-message-input {
  background-color: #F6F6F6 !important;
  padding: 8px 15px !important;
}

.cs-message-input__content-editor-wrapper {
  background-color: white !important;
  border-radius: 30px !important;
  border: 1px solid #E8E8E8;
}

.cs-message-input__content-editor {
  background-color: white !important;
}

.cs-message-group--incoming .cs-message__content, .cs-message--incoming .cs-message__content {
  background-color: #F9F9F9 !important;
  border-radius: 9px !important;
}

.cs-message-group--outgoing .cs-message__content, .cs-message--outgoing .cs-message__content {
  background-color: #DCF8C7 !important;
  border-radius: 9px !important;
}

.single-image .yarl__button {
  display: none;
}

.yarl__toolbar {
  width: 100% !important;
  padding: 0 !important;
}
